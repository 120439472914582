<template>
  <b-card>
    <validation-observer ref="simpleRules">
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="12">
            <b-form-group
              label="Contraseña Actual"
              label-for="account-contraseña-actual"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Contraseña Actual"
                rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-contraseña-actual"
                    v-model="passwordValueOld"
                    name="contraseña-actual"
                    :type="passwordFieldTypeOld"
                    placeholder="Contraseña Actual"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-nueva-contraseña"
              label="Nueva Contraseña"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nueva Contraseña"
                rules="required|password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-nueva-contraseña"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="nueva-contraseña"
                    placeholder="Nueva Contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-confirmar-nueva-contraseña"
              label="Confirmar Contraseña"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Confirmar Contraseña"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-confirmar-nueva-contraseña"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="confirmar-contraseña"
                    placeholder="Confirmar Contraseña"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-1 mr-1"
              @click="validation"
            >
              Guardar
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import userData from './SettingsData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
  },
  data() {
    return {
      required,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      settingsData: userData.user,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validation() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.passwordValueOld === this.settingsData[0].password) {
            if (this.newPasswordValue === this.RetypePassword) {
              this.updatedPassword()
              this.reset()
            } else {
              this.makeToast('danger', 'ERROR', 'Las contraseñas no coinciden')
              this.reset()
            }
          } else {
            this.makeToast('danger', 'ERROR', 'Contraseña incorrecta')
            this.reset()
          }
        } else {
          this.makeToast('danger', 'ERROR', 'No se ha podido cambiar la contraseña')
          this.reset()
        }
      })
    },
    updatedPassword() {
      this.makeToast('success', 'ÉXITO', 'Contraseña Actualizada')
      this.settingsData[0].password = this.newPasswordValue
    },
    reset() {
      this.RetypePassword = ''
      this.passwordValueOld = ''
      this.newPasswordValue = ''
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
