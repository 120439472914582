export default {
  user: [
    {
      name: 'Abner Luna',
      email: 'aluna@gmail.com',
      address: 'SMP',
      cel: '59786321',
      rol: 'ventas',
      region: 'Villa Nueva',
      password: 'Accesorios1$',
      picture: '',
    },
  ],
}
