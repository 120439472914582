<template>
  <b-card>

    <!-- media -->
    <div class="text-start">
      <b-avatar
        size="152px"
        class="mr-1"
        :src="userInfo.picture"
      />
      <b-button
        variant="outline-primary"
        @click="$refs.refInputEl.click()"
      >
        <input
          ref="refInputEl"
          type="file"
          class="d-none"
          @input="inputImageRenderer"
        >
        <feather-icon
          icon="EditIcon"
        />
      </b-button>
    </div>

    <!--/ media -->

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Nombre"
              label-for="account-name"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  placeholder="Nombre"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Email"
              label-for="account-email"
            >
              <b-form-input
                v-model="email"
                name="email"
                placeholder="Email"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Dirección"
              label-for="account-address"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Dirección"
                rules="required"
              >
                <b-form-input
                  v-model="address"
                  name="address"
                  placeholder="Dirección"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label="Teléfono"
              label-for="account-movil"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Teléfono"
                rules="required"
              >
                <b-form-input
                  v-model="cel"
                  placeholder="Teléfono"
                  name="movil"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label="Rol"
              label-for="account-rol"
            >
              <b-form-input
                v-model="rol"
                name="rol"
                placeholder="Rol"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              v-if="region.length > 0"
              label="Región de ventas asignada"
              label-for="account-region"
            >
              <b-form-input
                v-model="region"
                name="Region"
                placeholder="Región de ventas asignada"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mt-2 mr-1"
              @click="validation"
            >
              Guardar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import userData from './SettingsData'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      // props.userInfo.picture = base64
      console.log(props, base64)
    })

    return {
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      name: '',
      email: '',
      address: '',
      cel: '',
      rol: '',
      region: '',
      settingsData: userData.user,
      required,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.name = this.settingsData[0].name
      this.email = this.settingsData[0].email
      this.address = this.settingsData[0].address
      this.cel = this.settingsData[0].cel
      this.rol = this.settingsData[0].rol
      this.region = this.settingsData[0].region
    },
    validation() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.saveData()
        } else {
          this.makeToast('danger', 'Error', 'Debes llenar todos los campos requeridos')
        }
      })
    },
    saveData() {
      this.$bvModal
        .msgBoxConfirm('¿Deseas cambiar los datos del usuario?', {
          title: 'Modificar usuario',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            for (let index = 0; index < this.settingsData.length; index += 1) {
              const element = this.settingsData[index]
              element.name = this.name
              element.cel = this.cel
              element.address = this.address
              this.makeToast('success', 'Modificado', 'Se ha modificado el usuario correctamente')
            }
          }
        })
    },
  },
}
</script>
