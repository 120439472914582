<template>
  <div>
    <b-card>
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <!-- general tab -->
        <b-tab active>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">General</span>
          </template>
          <general :user-info="userInfo" />
        </b-tab>
        <!--/ general tab -->
        <!-- change password tab -->
        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Cambiar contraseña</span>
          </template>
          <password />
        </b-tab>
        <!--/ change password tab -->
        <!-- info -->
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import general from '@/views/settings/settings/General.vue'
import password from '@/views/settings/settings/information.vue'
import infoSettings from '@/views/settings/settings/SettingsData'

export default {
  components: {
    general,
    password,
  },
  data() {
    return {
      userInfo: infoSettings,
      options: {},
    }
  },
}
</script>
